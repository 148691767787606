import { Link } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import {
  BsFillPeopleFill,
  BsFillTrophyFill,
  BsPersonCircle,
  BsFillGearFill,
  BsPatchCheckFill,
  BsCurrencyExchange,
} from 'react-icons/bs';
import { BiLogOut } from 'react-icons/bi';
import ThemeSwitcher from './ThemeSwitcher';

const SidebarMenu = () => {
  return (
    <StickyBox className="flex h-full">
      <div className=" flex w-full flex-col bg-secondary text-main dark:bg-secondaryDark  dark:text-mainDark">
        <div className="flex h-20 flex-col items-start justify-center px-8 py-4">
          <Link
            to="/"
            className="dark:text-primary-900 hidden ps-2 text-xl font-bold text-black dark:text-white md:inline-block">
            Astro Fantasy
          </Link>
        </div>
        <div className="flex h-full w-full flex-col justify-between text-lg">
          <div className="flex w-full flex-col">
            <Link
              to="/teams"
              className="flex w-full items-center justify-center gap-4 rounded-lg px-8 py-6 hover:bg-tertiary hover:text-white  dark:hover:bg-tertiaryDark md:justify-start">
              <BsFillPeopleFill />
              <span className=" hidden md:block">Teams</span>
            </Link>
            <Link
              to="/players"
              className="flex w-full items-center justify-center gap-4 rounded-lg px-8 py-6 hover:bg-tertiary hover:text-white  dark:hover:bg-tertiaryDark md:justify-start">
              <BsPersonCircle />
              <span className=" hidden md:block">Players</span>
            </Link>
            <Link
              to="leagues/"
              className="flex w-full items-center justify-center gap-4 rounded-lg px-8 py-6 hover:bg-tertiary hover:text-white  dark:hover:bg-tertiaryDark md:justify-start">
              <BsFillTrophyFill />
              <span className=" hidden md:block">Game League</span>
            </Link>
            <Link
              to="challenges/"
              className="flex w-full items-center justify-center gap-4 rounded-lg px-8 py-6 hover:bg-tertiary hover:text-white  dark:hover:bg-tertiaryDark md:justify-start">
              <BsPatchCheckFill />
              <span className=" hidden md:block">Challenges</span>
            </Link>
            <Link
              to="sponsors/"
              className="flex w-full items-center justify-center gap-4 rounded-lg px-8 py-6 hover:bg-tertiary hover:text-white  dark:hover:bg-tertiaryDark md:justify-start">
              <BsCurrencyExchange />
              <span className=" hidden md:block">Sponsors</span>
            </Link>
            <Link
              to="/settings"
              className="flex w-full items-center justify-center gap-4 rounded-lg px-8 py-6 hover:bg-tertiary hover:text-white  dark:hover:bg-tertiaryDark md:justify-start">
              <BsFillGearFill />
              <span className=" hidden md:block">Settings</span>
            </Link>
          </div>
          <div className="flex h-20 items-center justify-between p-8">
            {/* //TODO: Add logout functionality */}
            <button className="flex items-center justify-center gap-4 text-red-500">
              <BiLogOut />
              <h3>Logout</h3>
            </button>
            <ThemeSwitcher />
          </div>
        </div>
      </div>
    </StickyBox>
  );
};

export default SidebarMenu;
