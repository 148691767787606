import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '../constants';
import { ICompetition } from '../../models/competition.model';
import { fetchCompetitions } from '../thunk/competitionThunks';

export interface CompetitionState {
  competitions: ICompetition[];
  status: DataState;
  error: string | null;
}

const initialState: CompetitionState = {
  competitions: [],
  status: DataState.IDLE,
  error: null,
};

export const CompetitionSlicer = createSlice({
  name: 'competition',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCompetitions.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchCompetitions.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.competitions = action.payload.data;
      })
      .addCase(fetchCompetitions.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      });
  },
});

export default CompetitionSlicer.reducer;
