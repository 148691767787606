// Filename - Components/Switcher.js

import { useState } from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import useDarkSide, { ThemeColor } from '../hooks/useTheme';

export default function Switcher() {
  const [colorTheme, setTheme] = useDarkSide();
  const [darkSide, setDarkSide] = useState(
    colorTheme === ThemeColor.LIGHT ? true : false,
  );

  const toggleDarkMode = (checked: boolean) => {
    setTheme(colorTheme);
    setDarkSide(checked);
  };

  return (
    <>
      <DarkModeSwitch
        // style={{ marginBottom: '2rem' }}
        sunColor="#FD7F20"
        moonColor="#ffffbf"
        checked={darkSide}
        onChange={toggleDarkMode}
        size={30}
      />
    </>
  );
}
