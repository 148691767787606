import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '../constants';
import { IAllSponsors } from '../../models/sponsor.model';
import {
  fetchSponsorBanners,
  fetchSponsorLogos,
  fetchTeamSponsor,
} from '../thunk/sponsorThunks';

export interface SponsorState {
  sponsors: IAllSponsors;
  status: DataState;
  error: string | null;
}

const initialState: SponsorState = {
  sponsors: {
    banners: [],
    logos: [],
    team: [],
  },
  status: DataState.IDLE,
  error: null,
};

export const SponsorshipSlice = createSlice({
  name: 'sponsorship',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSponsorLogos.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchSponsorLogos.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.sponsors.logos = action.payload.data;
      })
      .addCase(fetchSponsorLogos.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      })
      .addCase(fetchSponsorBanners.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchSponsorBanners.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.sponsors.banners = action.payload.data;
      })
      .addCase(fetchSponsorBanners.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      })
      .addCase(fetchTeamSponsor.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchTeamSponsor.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.sponsors.team = action.payload.data;
      })
      .addCase(fetchTeamSponsor.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      });
  },
});

export default SponsorshipSlice.reducer;
