import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getJwtToken } from '../../util/token';

export const fetchCompetitions = createAsyncThunk(
  'competition/fetchAll',
  async () => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/competitions`;
    const response = await axios.get(url, headers);
    return response.data;
  },
);
