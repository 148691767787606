export const Constants = {};

export enum DataState {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export const jwtToken = 'jwt_token';
