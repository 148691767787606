import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getJwtToken } from '../../util/token';

export const fetchPlayerCardTemplates = createAsyncThunk(
  'playerCardTemplate/fetch',
  async (playerId: number) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/card/players/${playerId}`;
    const response = await axios.get(url, headers);
    return response.data;
  },
);

export const fetchUpgradeCardTemplates = createAsyncThunk(
  'upgradeCardTemplate/fetch',
  async () => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/card/upgrade`;
    const response = await axios.get(url, headers);
    return response.data;
  },
);

export const fetchPackTemplates = createAsyncThunk(
  'packTemplate/fetch',
  async () => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/pack`;
    const response = await axios.get(url, headers);
    return response.data;
  },
);

export const createPlayerCardTemplate = createAsyncThunk(
  'playerCardTemplate/create',
  async (data: createPlayerCardTemplatePayload) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/card/players/${data.playerId}/resize/`;
    delete data.playerId;
    const response = await axios.post(url, data, headers);
    return response.data;
  },
);

export const createUpgradeCardTemplate = createAsyncThunk(
  'upgradeCardTemplate/create',
  async (data: uploadUpgradeCardPayload[]) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = {
      imageRef: data,
    };

    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/card/upgrade/resize/`;
    const response = await axios.post(url, body, headers);
    return response.data;
  },
);

export const createPackTemplate = createAsyncThunk(
  'packTemplate/create',
  async (data: uploadPackPayload[]) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = {
      imageRef: data,
    };

    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/pack/resize/`;
    const response = await axios.post(url, body, headers);
    return response.data;
  },
);

export const removePlayerCardTemplate = createAsyncThunk(
  'playerCardTemplate/delete',
  async (data: removePlayerCardTemplatePayload) => {
    const token = getJwtToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/card/players/${data.playerId}`;
    delete data.playerId;
    const response = await axios.delete(url, {
      headers,
      data,
    });
    return response.data;
  },
);

export type createPlayerCardTemplatePayload = {
  playerId?: number;
  teamId: number;
  competitionId: number;
  imageRef: createCardTemplateImageRef[];
};

export type removePlayerCardTemplatePayload = {
  playerId?: number;
  imageIds: string[];
};

export type uploadUpgradeCardPayload = {
  tackle?: string;
  save?: string;
  pass?: string;
  shoot?: string;
};

export type uploadPackPayload = {
  bronze?: string;
  silver?: string;
  gold?: string;
  platinum?: string;
  diamond?: string;
  amateur?: string;
  semipro?: string;
  pro?: string;
  starter?: string;
};

export type createCardTemplateImageRef = {
  common?: string;
  limited?: string;
  rare?: string;
  legendary?: string;
  unique?: string;
};
