import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '../constants';
import {
  fetchCardConfig,
  fetchPackConfig,
  fetchRarities,
} from '../thunk/configThunks';
import { ICardConfig, IPackConfig } from '../../models/cardConfig.model';

export interface ConfigState {
  cardConfig: ICardConfig;
  packConfig: IPackConfig;
  rarity: string[];
  status: DataState;
  error: string | null;
}

const initialState: ConfigState = {
  cardConfig: {
    playerCard: [],
    upgradeCard: [],
  },
  packConfig: {
    playerCardPacks: [],
    starterPacks: [],
    upgradeCardPacks: [],
  },
  rarity: [],
  status: DataState.IDLE,
  error: null,
};

export const ConfigSlicer = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCardConfig.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchCardConfig.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.cardConfig = action.payload.data;
      })
      .addCase(fetchCardConfig.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      })
      .addCase(fetchPackConfig.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchPackConfig.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.packConfig = action.payload.data;
      })
      .addCase(fetchPackConfig.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      })
      .addCase(fetchRarities.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchRarities.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.rarity = action.payload.data;
      })
      .addCase(fetchRarities.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      });
  },
});

export default ConfigSlicer.reducer;
