import { useState, useEffect } from 'react';

export enum ThemeColor {
  LIGHT = 'light',
  DARK = 'dark',
}

// Filename - hooks/useDarkSide.js

export default function useDarkSide() {
  const [theme, setTheme] = useState<ThemeColor>(localStorage.theme);
  const colorTheme =
    theme === ThemeColor.DARK ? ThemeColor.LIGHT : ThemeColor.DARK;
  localStorage.setItem('theme', theme);
  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove(colorTheme);
    root.classList.add(theme);
    if (localStorage.theme === ThemeColor.DARK) {
      localStorage.removeItem('theme');
      localStorage.setItem('theme', theme);
    } else localStorage.setItem('theme', theme);
  }, [theme, colorTheme]);

  return [colorTheme, setTheme] as const;
}
