import './App.css';
import './core/Interceptor';
import { Route, Routes, useLocation } from 'react-router-dom';
import { WithChildren } from './util/util';
import { FC, Suspense, lazy } from 'react';
import SidebarMenu from './components/Sidebar';
import PrivateRouters from './core/PrivateRouter';
import HomePage from './screens/HomePage';

function App() {
  const location = useLocation();

  const LoginPage = lazy(() => import('./screens/LoginPage'));
  const TeamsPage = lazy(() => import('./screens/TeamsPage'));
  const PlayersPage = lazy(() => import('./screens/PlayersPage'));
  const PlayerCardTemplatePage = lazy(
    () => import('./screens/PlayerCardTemplatePage'),
  );
  const CompetitionSettingsPage = lazy(
    () => import('./screens/CompetitionSettingsPage'),
  );
  const CardSettingsPage = lazy(() => import('./screens/CardSettingsPage'));
  const PackSettingsPage = lazy(() => import('./screens/PackSettingsPage'));
  const DefaultImagesSettingsPage = lazy(
    () => import('./screens/DefaultImagesPage'),
  );
  const LeaguesPage = lazy(() => import('./screens/LeaguePage'));
  const ChallengesPage = lazy(() => import('./screens/ChallengesPage'));
  const SponsorshipPage = lazy(() => import('./screens/SponsorshipPage'));
  const SettingsPage = lazy(() => import('./screens/SettingsPage'));
  const NotFoundPage = lazy(() => import('./screens/NotFoundPage'));
  return (
    <div className="App">
      <div className="grid h-screen w-screen grid-cols-6 overflow-hidden bg-primary dark:bg-primaryDark md:grid-cols-8 lg:grid-cols-10">
        {location.pathname !== '/login' && (
          <div className="col-span-1 w-full md:col-span-2 lg:col-span-2">
            <SidebarMenu />
          </div>
        )}
        <div className="col-span-5 h-full w-full md:col-span-6 lg:col-span-8">
          <Routes>
            <Route
              element={
                <SuspenseContainer>
                  <LoginPage />
                </SuspenseContainer>
              }
              path="login"
            />
            <Route element={PrivateRouters()}>
              <Route
                element={
                  <SuspenseContainer>
                    <HomePage />
                  </SuspenseContainer>
                }
                path="/"
              />
              <Route
                element={
                  <SuspenseContainer>
                    <TeamsPage />
                  </SuspenseContainer>
                }
                path="teams"
              />
              <Route
                element={
                  <SuspenseContainer>
                    <PlayersPage />
                  </SuspenseContainer>
                }
                path="players"
              />
              <Route
                element={
                  <SuspenseContainer>
                    <LeaguesPage />
                  </SuspenseContainer>
                }
                path="leagues"
              />
              <Route
                element={
                  <SuspenseContainer>
                    <ChallengesPage />
                  </SuspenseContainer>
                }
                path="challenges"
              />
              <Route
                element={
                  <SuspenseContainer>
                    <SponsorshipPage />
                  </SuspenseContainer>
                }
                path="sponsors"
              />
              <Route
                element={
                  <SuspenseContainer>
                    <SettingsPage />
                  </SuspenseContainer>
                }
                path="settings"
              />
              <Route
                element={
                  <SuspenseContainer>
                    <CompetitionSettingsPage />
                  </SuspenseContainer>
                }
                path="settings/competition"
              />
              <Route
                element={
                  <SuspenseContainer>
                    <CardSettingsPage />
                  </SuspenseContainer>
                }
                path="settings/card"
              />
              <Route
                element={
                  <SuspenseContainer>
                    <PackSettingsPage />
                  </SuspenseContainer>
                }
                path="settings/pack"
              />
              <Route
                element={
                  <SuspenseContainer>
                    <DefaultImagesSettingsPage />
                  </SuspenseContainer>
                }
                path="settings/default-images"
              />
              <Route
                element={
                  <SuspenseContainer>
                    <PlayerCardTemplatePage />
                  </SuspenseContainer>
                }
                path="settings/template/:playerId"
              />
              <Route
                element={
                  <SuspenseContainer>
                    <NotFoundPage />
                  </SuspenseContainer>
                }
                path="*"
              />
            </Route>
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;

const SuspenseContainer: FC<WithChildren> = ({ children }) => {
  // const baseColor = getCSSuspenseContainerariableValue('--bs-primary');
  // TopBarProgress.config({
  //   barColors: {
  //     '0': baseColor,
  //   },
  //   barThickness: 1,
  //   shadowBlur: 5,
  // });
  return <Suspense fallback={'Loading...'}>{children}</Suspense>;
};
