import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { TeamSlice } from './slicers/teamSlicer';
import { PlayerSlice } from './slicers/playerSlicer';
import { ConfigSlicer } from './slicers/configSlicer';
import { CurrentCompetitionSlicer } from './slicers/currentSeasonSlicer';
import { CompetitionSlicer } from './slicers/competitionSlicer';
import { CardTemplateSlicer } from './slicers/cardTemplateSlicer';
import { GameLeagueSlicer } from './slicers/gameLeagueSlicer';
import { GameLeagueHistorySlicer } from './slicers/gameLeagueHistorySlicer';
import { PreviousCompetitionSlicer } from './slicers/previousSeasonSlicer';
import { ChallengeOptionSlicer } from './slicers/challengeOptionSlicer';
import { ChallengeTemplateSlicer } from './slicers/challengeTemplateSlicer';
import { CurrentWeekSlicer } from './slicers/currentWeekSlicer';
import { SponsorshipSlice } from './slicers/sponsorSlicer';
import { DefaultImagesSlicer } from './slicers/defaultImagesSlicer';

export const store = configureStore({
  reducer: {
    team: TeamSlice.reducer,
    player: PlayerSlice.reducer,
    config: ConfigSlicer.reducer,
    currentSeason: CurrentCompetitionSlicer.reducer,
    previousSeason: PreviousCompetitionSlicer.reducer,
    competition: CompetitionSlicer.reducer,
    templates: CardTemplateSlicer.reducer,
    leagues: GameLeagueSlicer.reducer,
    leagueHistory: GameLeagueHistorySlicer.reducer,
    challengeOptions: ChallengeOptionSlicer.reducer,
    challengeTemplate: ChallengeTemplateSlicer.reducer,
    currentWeek: CurrentWeekSlicer.reducer,
    sponsorship: SponsorshipSlice.reducer,
    defaultImages: DefaultImagesSlicer.reducer,
  },
});

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;
