import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '../constants';
import { IPlayer } from '../../models/player.model';
import { fetchPlayerById, fetchPlayers } from '../thunk/playerThunks';

export interface PlayerState {
  players: IPlayer[];
  totalPages: number;
  totalCount: number;
  status: DataState;
  error: string | null;
}

const initialState: PlayerState = {
  players: [],
  totalPages: 0,
  totalCount: 0,
  status: DataState.IDLE,
  error: null,
};

export const PlayerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPlayers.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchPlayers.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.players = action.payload.data;
        state.totalPages = action.payload.total_page;
        state.totalCount = action.payload.total_count;
      })
      .addCase(fetchPlayers.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      })
      .addCase(fetchPlayerById.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchPlayerById.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        if (action.payload.is_success) {
          state.players = [action.payload.data];
        }
      })
      .addCase(fetchPlayerById.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      });
  },
});

export default PlayerSlice.reducer;
