import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '../constants';
import { IPreviousSeason } from '../../models/season.model';
import { fetchPreviousSeason } from '../thunk/currentSeasonThunks';

export interface PreviousCompetitionState {
  competitions: IPreviousSeason[];
  status: DataState;
  error: string | null;
}

const initialState: PreviousCompetitionState = {
  competitions: [],
  status: DataState.IDLE,
  error: null,
};

export const PreviousCompetitionSlicer = createSlice({
  name: 'previousSeason',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPreviousSeason.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchPreviousSeason.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.competitions = action.payload.data;
      })
      .addCase(fetchPreviousSeason.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      });
  },
});

export default PreviousCompetitionSlicer.reducer;
