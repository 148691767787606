import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  IPackConfigInput,
  IPlayerCardConfigInput,
} from '../../models/cardConfig.model';
import { getJwtToken } from '../../util/token';

export const fetchCardConfig = createAsyncThunk('cards/config', async () => {
  const token = getJwtToken();
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/config/card`;
  const response = await axios.get(url, headers);
  return response.data;
});

export const fetchPackConfig = createAsyncThunk('packs/config', async () => {
  const token = getJwtToken();
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/config/pack`;
  const response = await axios.get(url, headers);
  return response.data;
});

export const fetchRarities = createAsyncThunk('cards/rarity', async () => {
  const token = getJwtToken();
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/config/rarity`;
  const response = await axios.get(url, headers);
  return response.data;
});

export const updatePlayerCardConfig = createAsyncThunk(
  'config/updateCardConfig',
  async (configs: IPlayerCardConfigInput[]) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/config/card`;
    const body = {
      configs: configs,
    };
    const response = await axios.post(url, body, headers);
    return response.data;
  },
);

export const updatePackConfig = createAsyncThunk(
  'config/updatePackConfig',
  async (configs: IPackConfigInput[]) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/config/pack`;
    const body = {
      configs: configs,
    };
    const response = await axios.post(url, body, headers);
    return response.data;
  },
);
