import { createSlice } from '@reduxjs/toolkit';
import { ITeam } from '../../models/team.model';
import {
  fetchTeams,
  fetchTeamById,
  fetchCurrentSeasonTeams,
} from '../thunk/teamThunks';
import { DataState } from '../constants';

export interface TeamState {
  teams: ITeam[];
  status: DataState;
  error: string | null;
}

const initialState: TeamState = {
  teams: [],
  status: DataState.IDLE,
  error: null,
};

export const TeamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTeams.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchTeams.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.teams = action.payload.data;
      })
      .addCase(fetchTeams.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      })
      .addCase(fetchCurrentSeasonTeams.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchCurrentSeasonTeams.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.teams = action.payload.data;
      })
      .addCase(fetchCurrentSeasonTeams.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      })
      .addCase(fetchTeamById.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchTeamById.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        const existingTeam = state.teams.find(
          (team) => team.gs_team_id === action.payload.gs_team_id,
        );
        if (existingTeam) {
          state.teams = state.teams.map((team) => {
            if (team.gs_team_id === action.payload.gs_team_id) {
              return action.payload.data;
            }
            return team;
          });
        } else {
          state.teams.push(action.payload.data);
        }
      })
      .addCase(fetchTeamById.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      });
  },
});

export default TeamSlice.reducer;
