import axios from 'axios';

let unauthorizeFirstTime = false;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      if (!unauthorizeFirstTime) {
        unauthorizeFirstTime = true;
        window.alert('Your session has expired. Please login again.');
        window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  },
);
