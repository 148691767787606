import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '../constants';
import {
  ISeasonalChallengeTemplate,
  IWeeklyChallengeTemplate,
} from '../../models/challengeTemplate.model';
import {
  fetchSeasonalChallengeTemplate,
  fetchWeeklyChallengeTemplate,
} from '../thunk/challengeTemplateThunks';

export interface ChallengeTemplateState {
  weekly: IWeeklyChallengeTemplate[];
  seasonal: ISeasonalChallengeTemplate[];
  status: DataState;
  error: string | null;
}

const initialState: ChallengeTemplateState = {
  weekly: [],
  seasonal: [],
  status: DataState.IDLE,
  error: null,
};

export const ChallengeTemplateSlicer = createSlice({
  name: 'challengeTemplate',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchWeeklyChallengeTemplate.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchWeeklyChallengeTemplate.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.weekly = action.payload.data;
      })
      .addCase(fetchWeeklyChallengeTemplate.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      })
      .addCase(fetchSeasonalChallengeTemplate.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchSeasonalChallengeTemplate.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.seasonal = action.payload.data;
      })
      .addCase(fetchSeasonalChallengeTemplate.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      });
  },
});

export default ChallengeTemplateSlicer.reducer;
