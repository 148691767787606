import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '../constants';
import { IGameLeagueObject } from '../../models/gameLeague.model';
import { fetchPublicGameLeagueHistory } from '../thunk/gameLeagueThunks';

export interface GameLeagueState {
  gameLeaguesHistory: IGameLeagueObject[];
  status: DataState;
  error: string | null;
}

const initialState: GameLeagueState = {
  gameLeaguesHistory: [],
  status: DataState.IDLE,
  error: null,
};

export const GameLeagueHistorySlicer = createSlice({
  name: 'gameLeaguesHistory',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPublicGameLeagueHistory.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchPublicGameLeagueHistory.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.gameLeaguesHistory = action.payload.data;
      })
      .addCase(fetchPublicGameLeagueHistory.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      });
  },
});

export default GameLeagueHistorySlicer.reducer;
