import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getJwtToken } from '../../util/token';

export const fetchAllDefaultProfileImages = createAsyncThunk(
  'defaultProfileImages/fetchAll',
  async () => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/images/user/default`;
    const response = await axios.get(url, headers);
    return response.data;
  },
);

export const removeDefaultProfileImages = createAsyncThunk(
  'defaultProfileImages/remove',
  async (data: removeDefaultProfilePayload) => {
    const token = getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      data,
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/images/user/default`;
    const response = await axios.delete(url, config);
    return response.data;
  },
);

export const addDefaultProfileImages = createAsyncThunk(
  'defaultProfileImages/add',
  async (data: addDefaultProfilePayload[]) => {
    const token = getJwtToken();
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const body = {
      imageRef: data,
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/images/user/default/resize`;
    const response = await axios.post(url, body, headers);
    return response.data;
  },
);

export type removeDefaultProfilePayload = {
  imageId: string;
};

export type addDefaultProfilePayload = {
  default_user_image: string;
};
