import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getJwtToken } from '../../util/token';
import { EChallengeType } from '../../models/challenge.enum';
import { EPackTier } from '../../models/cardConfig.model';

export const fetchChallengeOptions = createAsyncThunk(
  'challengeOptions/fetchAll',
  async () => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/challenges/options`;
    const response = await axios.get(url, headers);
    return response.data;
  },
);

export const updateOneChallengeOptions = createAsyncThunk(
  'challengeOptions/updateReward',
  async (data: updateChallengeOptionRewardPayload[]) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = {
      options: data,
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/challenges/options`;
    const response = await axios.post(url, body, headers);
    return response.data;
  },
);

export const addChallengeOptions = createAsyncThunk(
  'challengeOptions/addReward',
  async (data: addChallengeOptionRewardsPayload[]) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = {
      options: data,
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/challenges/options/add`;
    const response = await axios.post(url, body, headers);
    return response.data;
  },
);

export const deleteChallengeOptions = createAsyncThunk(
  'challengeOptions/addReward',
  async (data: deleteChallengeOptionRewardsPayload[]) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = {
      options: data,
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/challenges/options/remove`;
    const response = await axios.post(url, body, headers);
    return response.data;
  },
);

export type updateChallengeOptionRewardPayload = {
  tier?: EPackTier;
  type: EChallengeType;
  rewardId: string;
  quantity?: number;
};

export type addChallengeOptionRewardsPayload = {
  tier: EPackTier;
  type: EChallengeType;
  quantity: number;
};

export type deleteChallengeOptionRewardsPayload = {
  rewardId: string;
  type: EChallengeType;
};
