import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getJwtToken } from '../../util/token';
// import axios from '../../core/axios';

export const fetchTeams = createAsyncThunk('teams/fetchAll', async () => {
  const token = getJwtToken();
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/teams/current`;
  const response = await axios.get(url, headers);
  if (response.data.data) {
    return response.data;
  } else {
    return [];
  }
});

export const fetchCurrentSeasonTeams = createAsyncThunk(
  'teams/fetchCurrent',
  async () => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/teams/current`;
    const response = await axios.get(url, headers);
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  },
);

export const fetchTeamById = createAsyncThunk(
  'teams/fetchTeamById',
  async (teamId: number) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/teams/${teamId}`;
    const response = await axios.get(url, headers);
    return response.data;
  },
);

export const updateTeams = createAsyncThunk(
  'teams/updateTeams',
  async (data: updateTeamPayload) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/teams/${data.teamId}`;
    delete data.teamId;
    const response = await axios.post(url, data, headers);
    return response.data;
  },
);

export type updateTeamPayload = {
  teamId?: string;
  officialName?: string;
  shortName?: string;
  imageRef?: string;
};
