import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getJwtToken } from '../../util/token';

export const fetchCurrentWeekNumber = createAsyncThunk(
  'currentWeek/fetchCurrent',
  async () => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/challenges/week-number`;
    const response = await axios.get(url, headers);
    return response.data;
  },
);

export const fetchTotalWeeksInCompetition = createAsyncThunk(
  'currentWeek/fetchAllWeeks',
  async (competitionId: number) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/competitions/${competitionId}/weeks`;
    const response = await axios.get(url, headers);
    return response.data;
  },
);
