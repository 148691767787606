export enum EChallengeDurationType {
  WEEKLY = 'weekly',
  SEASONAL = 'seasonal',
}

export enum EChallengeType {
  // seasonal
  LOYALIST_ONE = 'loyalist_one',
  LOYALIST_TWO = 'loyalist_two',
  LOYALIST_THREE = 'loyalist_three',
  LOYALIST_FOUR = 'loyalist_four',
  TOP_COACH = 'top_coach',
  SWITCH_THINGS_UP = 'switch_things_up',
  CAPTAIN_FANTASTIC_FORWARD = 'captain_fantastic_forward',
  CAPTAIN_FANTASTIC_MIDFIELDER = 'captain_fantastic_midfielder',
  CAPTAIN_FANTASTIC_DEFENDER = 'captain_fantastic_defender',
  CAPTAIN_FANTASTIC_GOALKEEPER = 'captain_fantastic_goalkeeper',
  // weekly
  STRATEGIST = 'strategist',
  HERE_WE_GO = 'here_we_go',
  TEAMWORK = 'teamwork',
  TACTICAL_MASTER = 'tactical_master',
  MAESTRO_POSITIONING = 'maestro_positioning',
  SHARPSHOOTER = 'sharpshooter',
  BRACE = 'brace',
  HAT_TRICK_HERO = 'hat_trick_hero',
  TIKI_TAKA = 'tiki_taka',
  PLAYMAKER = 'playmaker',
  ASSIST_KING = 'assist_king',
  LONG_RANGER = 'long_ranger',
  FIRST_LINE_OF_DEFENCE = 'first_line_of_defence',
  PARK_THE_BUS = 'park_the_bus',
  UNSUNG_HERO = 'unsung_hero',
  SAFE_HANDS = 'safe_hands',
  GAME_CHANGER = 'game_changer',
  ASTRO_USER = 'astro_user',
}

export enum EChallengeCategory {
  ENGAGEMENT = 'engagement',
  TRANSACTIONAL = 'transactional',
  SPECIAL = 'special',
  TEAM = 'team',
  FORWARD = 'forward',
  MIDFIELD = 'midfield',
  DEFENCE = 'defence',
  GK = 'gk',
}

export enum EEventSource {
  PASSIVE = 'passive',
  ACTIVE = 'active',
}

export type TChallengeParams =
  | 'team_id'
  | 'player_id'
  | 'position'
  | 'quantity'
  | 'formation';

export enum ELineUpType {
  LINEUP442 = '4-4-2',
  LINEUP433 = '4-3-3',
  LINEUP352 = '3-5-2',
  LINEUP334 = '3-3-4',
  LINEUP343 = '3-4-3',
  LINEUP424 = '4-2-4',
  LINEUP532 = '5-3-2',
  LINEUP523 = '5-2-3',
}

export enum EPlayerPosition {
  GOALKEEPER = 'GOALKEEPER',
  FORWARD = 'FORWARD',
  MIDFIELDER = 'MIDFIELDER',
  DEFENDER = 'DEFENDER',
}
