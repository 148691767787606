import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '../constants';
import { fetchCurrentSeason } from '../thunk/currentSeasonThunks';
import { ICurrentSeason } from '../../models/season.model';

export interface CurrentCompetitionState {
  competitions: ICurrentSeason[];
  status: DataState;
  error: string | null;
}

const initialState: CurrentCompetitionState = {
  competitions: [],
  status: DataState.IDLE,
  error: null,
};

export const CurrentCompetitionSlicer = createSlice({
  name: 'currentSeason',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCurrentSeason.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchCurrentSeason.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.competitions = action.payload.data;
      })
      .addCase(fetchCurrentSeason.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      });
  },
});

export default CurrentCompetitionSlicer.reducer;
