import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getJwtToken } from '../../util/token';

export const fetchSponsorLogos = createAsyncThunk('sponsor/logos', async () => {
  const token = getJwtToken();
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/sponsors/logos/carousel`;
  const response = await axios.get(url, headers);
  return response.data;
});

export const fetchSponsorBanners = createAsyncThunk(
  'sponsor/banners',
  async () => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/sponsors/banners`;
    const response = await axios.get(url, headers);
    console.log(response.data);
    return response.data;
  },
);

export const fetchTeamSponsor = createAsyncThunk(
  'sponsor/fetchTeam',
  async () => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/sponsors/team`;
    const response = await axios.get(url, headers);
    return response.data;
  },
);

export const uploadSponsorLogoImage = createAsyncThunk(
  'sponsor/uploadLogo',
  async (data: any[]) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = {
      imageRef: data,
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/sponsors/logos`;
    const response = await axios.post(url, body, headers);
    return response.data;
  },
);

export const uploadSponsorBannerImage = createAsyncThunk(
  'sponsor/uploadLogo',
  async (data: any[]) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = {
      imageRef: data,
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/sponsors/banners`;
    const response = await axios.post(url, body, headers);
    return response.data;
  },
);

export const uploadTeamSponsorImage = createAsyncThunk(
  'sponsor/uploadTeamCo',
  async (data: uploadTeamSponsorImagePayload) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = {
      imageRef: data.imageRef,
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/sponsors/team/${data.teamId}/resize`;
    const response = await axios.post(url, body, headers);
    return response.data;
  },
);

export const removeSponsor = createAsyncThunk(
  'sponsor/delete',
  async (data: removeSponsorPayload) => {
    const token = getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      data,
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/sponsors`;
    const response = await axios.delete(url, config);
    return response.data;
  },
);

export type uploadTeamSponsorImagePayload = {
  teamId: number;
  imageRef: any[];
};

export type removeSponsorPayload = {
  imageId: string;
};
