import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getJwtToken } from '../../util/token';

export const fetchPlayers = createAsyncThunk(
  'teams/fetchPlayers',
  async (data: fetchPlayersDataType) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/players/search/${data.page}`;
    const body = {
      keyword: data.keyword,
      positionFilter: data.positionFilter,
      clubFilter: data.clubFilter,
    };
    const response = await axios.post(url, body, headers);
    return response.data;
  },
);

export const fetchPlayerById = createAsyncThunk(
  'teams/fetchPlayerById',
  async (playerId: number) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/players/${playerId}`;
    const response = await axios.get(url, headers);
    console.log(response.data);
    return response.data;
  },
);

export type fetchPlayersDataType = {
  page: number;
  keyword: string;
  positionFilter: string[];
  clubFilter: number[];
};
