import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '../constants';
import {
  IPlayerCardTemplate,
  IUpgradeCardTemplate,
} from '../../models/cardTemplate.model';
import {
  fetchPlayerCardTemplates,
  fetchUpgradeCardTemplates,
} from '../thunk/cardTemplateThunks';

export interface CardTemplateState {
  player: IPlayerCardTemplate[];
  upgrade: IUpgradeCardTemplate[];
  status: DataState;
  error: string | null;
}

const initialState: CardTemplateState = {
  player: [],
  upgrade: [],
  status: DataState.IDLE,
  error: null,
};

export const CardTemplateSlicer = createSlice({
  name: 'template',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPlayerCardTemplates.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchPlayerCardTemplates.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.player = action.payload.data;
      })
      .addCase(fetchPlayerCardTemplates.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      })
      .addCase(fetchUpgradeCardTemplates.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchUpgradeCardTemplates.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.upgrade = action.payload.data;
      })
      .addCase(fetchUpgradeCardTemplates.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      });
  },
});

export default CardTemplateSlicer.reducer;
