import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getJwtToken } from '../../util/token';

export const fetchPublicGameLeagues = createAsyncThunk(
  'gameleague/fetch',
  async () => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/leagues/public`;
    const response = await axios.get(url, headers);
    return response.data;
  },
);

export const fetchPublicGameLeagueHistory = createAsyncThunk(
  'gameleague/history',
  async () => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/leagues/public/history`;
    const response = await axios.get(url, headers);
    return response.data;
  },
);

export const createGameLeague = createAsyncThunk(
  'gameleague/create',
  async (data: gameLeagueInput) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/leagues`;
    const response = await axios.post(url, data, headers);
    if (response.status === 200) {
      return response.data;
    }
  },
);

type gameLeagueInput = {
  name: string;
  competitionId: number;
  description: string;
  imageRef: string;
};
