import { jwtToken } from '../states/constants';

export const setJwtToken = (token: string) => {
  try {
    sessionStorage.setItem(jwtToken, token);
  } catch (e: unknown) {
    console.error(e);
  }
};

export const getJwtToken = () => {
  try {
    return sessionStorage.getItem(jwtToken);
  } catch (e: unknown) {
    console.error(e);
  }
};

// class Auth {
//   private _jwtToken: string;

//   public get token() {
//     this._jwtToken = sessionStorage.getItem(jwtToken)!;
//     return this._jwtToken;
//   }

//   public set token(token: string) {
//     sessionStorage.setItem(jwtToken, token);
//     this._jwtToken = token;
//   }
// }

// export default Auth;
