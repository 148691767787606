import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getJwtToken } from '../../util/token';
import {
  EChallengeDurationType,
  EChallengeType,
} from '../../models/challenge.enum';

export const fetchWeeklyChallengeTemplate = createAsyncThunk(
  'challengeWeeklyTemplate/fetchAll',
  async () => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/challenges/weekly/template`;
    const response = await axios.get(url, headers);
    return response.data;
  },
);

export const fetchSeasonalChallengeTemplate = createAsyncThunk(
  'challengeSeasonalTemplate/fetchAll',
  async () => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/challenges/seasonal/template`;
    const response = await axios.get(url, headers);
    return response.data;
  },
);

export const createWeeklyChallengeTemplate = createAsyncThunk(
  'challengeWeeklyTemplate/create',
  async (data: createWeeklyTemplatePayload) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = {
      weekNumber: data.weekNumber,
      type: EChallengeDurationType.WEEKLY,
      challenges: data.challenges,
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/challenges/template`;
    const response = await axios.post(url, body, headers);
    return response.data;
  },
);

export const createSeasonalChallengeTemplate = createAsyncThunk(
  'challengeSeasonalTemplate/create',
  async (data: createSeasonalTemplatePayload) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = {
      type: EChallengeDurationType.SEASONAL,
      challenges: data.challenges,
    };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/challenges/template`;
    const response = await axios.post(url, body, headers);
    return response.data;
  },
);

export const removeWeeklyChallengeTemplate = createAsyncThunk(
  'challengeWeeklyTemplate/delete',
  async (weekNumber: number) => {
    const token = getJwtToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const body = { weekNumber };
    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/challenges/weekly/template`;
    const response = await axios.delete(url, {
      headers,
      data: body,
    });
    return response.data;
  },
);

export type createWeeklyTemplatePayload = {
  weekNumber: number;
  challenges: { type: EChallengeType; payload: any }[];
};

export type createSeasonalTemplatePayload = {
  challenges: { type: EChallengeType; payload: any }[];
};
