import { createSlice } from '@reduxjs/toolkit';
import { IChallengeOption } from '../../models/challengeOption.model';
import { DataState } from '../constants';
import { fetchChallengeOptions } from '../thunk/challengeOptionThunks';

export interface ChallengeOptionState {
  challengeOption: IChallengeOption[];
  status: DataState;
  error: string | null;
}

const initialState: ChallengeOptionState = {
  challengeOption: [],
  status: DataState.IDLE,
  error: null,
};

export const ChallengeOptionSlicer = createSlice({
  name: 'challengeOption',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchChallengeOptions.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchChallengeOptions.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.challengeOption = action.payload.data;
      })
      .addCase(fetchChallengeOptions.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      });
  },
});

export default ChallengeOptionSlicer.reducer;
