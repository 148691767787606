import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '../constants';
import { fetchAllDefaultProfileImages } from '../thunk/defaultImagesThunks';
import { IDefaultProfileImage } from '../../models/defaultProfileImages.model';

export interface DefaultImagesState {
  profileImages: IDefaultProfileImage[];
  status: DataState;
  error: string | null;
}

const initialState: DefaultImagesState = {
  profileImages: [],
  status: DataState.IDLE,
  error: null,
};

export const DefaultImagesSlicer = createSlice({
  name: 'defaultImages',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllDefaultProfileImages.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchAllDefaultProfileImages.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.profileImages = action.payload.data;
      })
      .addCase(fetchAllDefaultProfileImages.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      });
  },
});

export default DefaultImagesSlicer.reducer;
