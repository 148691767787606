import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getJwtToken } from '../../util/token';

export const fetchCurrentSeason = createAsyncThunk(
  'season/currentFetch',
  async () => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/config/competition/season/current`;
    const response = await axios.get(url, headers);
    return response.data;
  },
);

export const fetchPreviousSeason = createAsyncThunk(
  'season/previousFetch',
  async () => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/config/competition/season/inactive`;
    const response = await axios.get(url, headers);
    return response.data;
  },
);

export const addCurrentSeason = createAsyncThunk(
  'season/add',
  async (data: addCompetitionInput) => {
    const token = getJwtToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/config/competition/season/current`;
    const response = await axios.post(url, data, headers);
    if (response.status === 200) {
      return response.data;
    }
  },
);

export const endCurrentSeason = createAsyncThunk(
  'season/end',
  async (competitionId: number) => {
    const token = getJwtToken();
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const data = { competitionId };

    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/config/competition/season/end`;
    const response = await axios.post(url, data, headers);
    return response.data;
  },
);

export const removeCurrentSeason = createAsyncThunk(
  'season/remove',
  async (competitionId: number) => {
    const token = getJwtToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      data: { competitionId },
    };

    const url = `${process.env.REACT_APP_ASTRO_FANTASY_BASE_URL}/admin/config/competition/season/current`;
    const response = await axios.delete(url, config);
    return response.data;
  },
);

export type addCompetitionInput = {
  competitionId: number;
  midSeason: string;
};
