import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '../constants';
import {
  fetchCurrentWeekNumber,
  fetchTotalWeeksInCompetition,
} from '../thunk/currentWeekThunks';

export interface CurrentWeekState {
  currentWeek: number;
  totalWeeks: number;
  status: DataState;
  error: string | null;
}

const initialState: CurrentWeekState = {
  currentWeek: 0,
  totalWeeks: 0,
  status: DataState.IDLE,
  error: null,
};

export const CurrentWeekSlicer = createSlice({
  name: 'currentWeek',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCurrentWeekNumber.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchCurrentWeekNumber.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.currentWeek = action.payload.data;
      })
      .addCase(fetchCurrentWeekNumber.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      })
      .addCase(fetchTotalWeeksInCompetition.pending, (state) => {
        state.status = DataState.LOADING;
      })
      .addCase(fetchTotalWeeksInCompetition.fulfilled, (state, action) => {
        state.status = DataState.SUCCEEDED;
        state.totalWeeks = action.payload.data;
      })
      .addCase(fetchTotalWeeksInCompetition.rejected, (state, action) => {
        state.status = DataState.FAILED;
        state.error = action.error.message!;
      });
  },
});

export default CurrentWeekSlicer.reducer;
